import React, { useEffect, useMemo, useState } from 'react';
import styles from './SkillsResult.module.scss';

import { useDispatch, useSelector } from 'react-redux';

// UTILS
import { useTranslate } from 'utils/translator';
import { filterHiddenResults, filterResultSchema } from 'utils/assessment';

import { getAssessment } from 'store/actions';
import {
  Button,
  InfoTitle,
  Modal,
  ShowResultsButton,
} from 'ui/basic';
import { SteppedScaleResultItem } from '../SteppedScaleResultItem';
import SkillsResultSkeleton from './SkillsResultSkeleton';
import { CustomReport } from '../CustomReport';
import { DownloadResultButton } from '../DownloadResultButton';
import { DownloadFileButton } from '../DownloadFileButton';

// STORE
import { selectAssessmentPdfDownloadHandles } from 'store/selectors/assessment';


const SkillsResult = (props) => {
  const {
    assessmentType,
    assessmentData,
    results,
    noDataText,
    footerText,
    showExportIcon,
    showInfoTitle = true,
    exportView,
    onExport = () => {},
    userId,
    managerControlled,
    sharedWithUser,
    historyIndex,
    showReportButton = true,
  } = props;

  const dispatch = useDispatch();
  const translate = useTranslate();

  const [ infoPageIsVisible, setInfoPageIsVisible ] = useState(false);
  const [ infoPageTitle, setInfoPageTitle ] = useState();
  const [ infoPageDescription, setInfoPageDescription ] = useState();
  const [ showReport, setShowReport ] = useState(false);

  let assessment = useSelector((state) => state.assessments.table[assessmentType]);
  if (assessmentData) {
    assessment = assessmentData;
  }

  const resultsFiltered = useMemo(() => filterHiddenResults(results), [ results ]);
  const resultSchemaFiltered = useMemo(() => (
    filterResultSchema(assessment?.resultSchema, resultsFiltered)
  ), [ assessment?.resultSchema, resultsFiltered ]);

  const downloadHandles = useSelector((state) => selectAssessmentPdfDownloadHandles(
    state,
    { ...props, assessmentData: assessment },
  ));

  const [ data, setData ] = useState();
  useEffect(() => {
    if (!assessment || !resultSchemaFiltered || !resultsFiltered) {
      return;
    }

    const mappedData = resultSchemaFiltered
    .filter((resultSchemaItem) => resultSchemaItem.type !== 'string')
    .map((resultSchemaItem) => {
      const mapDimension = (dimension, parentDimension) => {
        const dimensionResultItem = resultsFiltered.find(({ id }) => id === dimension.id);
        return {
          ...dimension,
          parentDimension,
          subDimensions: dimension.subDimensions?.map((el) => mapDimension(el, dimension.id)),
          result: dimensionResultItem?.result,
          referenceValue: dimensionResultItem?.range?.[0],
        };
      };
      return mapDimension(resultSchemaItem);
    });

    setData(mappedData);
  }, [ assessment, resultsFiltered ]);

  useEffect(() => {
    if (!assessment?.resultSchema) {
      dispatch(getAssessment(assessmentType, userId));
    }
  }, [ dispatch, assessmentType, assessment, userId ]);


  if (!data) {
    return (
      <div className={styles.skillsResult}>
        <SkillsResultSkeleton />
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className={styles.skillsResult}>
        <div id='container-id' className={styles.container}>
          <div className={styles.mainHeader}>
            { (exportView || !showInfoTitle) ? (
              <strong className='bluTypeLabel'>
                { assessment.title }
              </strong>
            ) : (
              <InfoTitle
                title={assessment.title}
                className={styles.infoTitle}
                onClick={() => {
                  setInfoPageTitle(assessment.title);
                  setInfoPageDescription(translate(assessment.description));
                  setInfoPageIsVisible(true);
                }}
              />
            ) }
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.skillsResult}>
      <div id='container-id' className={styles.container}>
        <div className={styles.mainHeader}>
          { (exportView || !showInfoTitle) ? (
            <strong className='bluTypeLabel'>
              { assessment.title }
            </strong>
          ) : (
            <InfoTitle
              title={assessment.title}
              className={styles.infoTitle}
              onClick={() => {
                setInfoPageTitle(assessment.title);
                setInfoPageDescription(translate(assessment.description));
                setInfoPageIsVisible(true);
              }}
            />
          ) }

          { (!exportView && showExportIcon && !noDataText) && (
            <DownloadResultButton
              onExport={onExport}
              downloadHandles={downloadHandles}
            />
          ) }
        </div>

        { noDataText && (
          <div className={styles.noDataText}>
            { noDataText }
          </div>
        ) }

        { !noDataText && (
          <>
            { managerControlled && (
              <div className={styles.controlled}>
                <ShowResultsButton
                  userId={userId}
                  assessmentId={assessmentType}
                  sharedWithUser={sharedWithUser}
                />
              </div>
            ) }

            <div className={styles.rows}>
              { data.map((dimension) => (
                <SteppedScaleResultItem
                  key={dimension.id}
                  data={dimension}
                  segmentsCount={assessment && assessment.renderReferenceMax}
                  isExpandable={!exportView}
                  onInfoClick={(subDimensionIndex, subDimension) => {
                    setInfoPageTitle(subDimension && subDimension.name);
                    setInfoPageDescription(subDimension && translate(subDimension.description || subDimension.name));
                    setInfoPageIsVisible(true);
                  }}
                />
              )) }
            </div>

            { !exportView && showReportButton && assessment.customReport && (
              <div className={styles.buttons}>
                <DownloadFileButton {...downloadHandles} />

                <div>
                  <Button
                    size='S'
                    looks='secondary'
                    onClick={() => setShowReport(true)}
                  >
                    { translate('9levels_results_show_report_btn') }
                  </Button>
                </div>
              </div>
            ) }

            { footerText && (
              <div className={styles.footerText}>
                { footerText }
              </div>
            ) }
          </>
        ) }

        { infoPageIsVisible && (
          <Modal
            header={infoPageTitle}
            secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn')}
            onConfirm={() => setInfoPageIsVisible(false)}
            onClose={() => setInfoPageIsVisible(false)}
          >
            { infoPageDescription }
          </Modal>
        ) }

        { showReport && (
          <CustomReport
            historyIndex={historyIndex}
            userId={userId}
            assessment={assessment}
            assessmentResults={data}
            assessmentResultsAll={data}
            onClose={() => setShowReport(false)}
            downloadHandles={downloadHandles}
          />
        ) }
      </div>
    </div>
  );
};

export default SkillsResult;
