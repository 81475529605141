import React from 'react';

import { marked } from 'marked';
import sanitize from 'insane';
import { convertFromRaw } from 'draft-js';
import { stateToMarkdown } from 'draft-js-export-markdown';
import { setDecimalSeparator } from './userpreference';

// https://marked.js.org/using_advanced#options
marked.setOptions({
  gfm: true,
  breaks: true,
});

export const capitalise = (string = '') => {
  if (!string || typeof (string) !== 'string') return string;

  return `${string[0].toUpperCase()}${string.slice(1)}`;
};

export const emptyDash = (value, emptySymbol = '-') => (value || emptySymbol);

export const getInitials = (label) => label.split(' ').slice(0, 2)
.map((name) => name && name.length && name[0])
.join('');

export const isJSON = (text = '') => {
  try {
    return !!JSON.parse(text);
  } catch (error) {
    return false;
  }
};

export const percentWithDecimal = (number) => {
  if (!number) {
    return '-';
  }
  return `${setDecimalSeparator(number, 1, { minimumFractionDigits: null })} %`;
};

export const joinNumbers = (
  numbers,
  delimiter = '-',
  numberFunction = (number) => number,
) => numbers.map((number) => numberFunction(number)).join(delimiter);

export const highlight = (valueParam = '', filter = '', highlightClassName = 'blu-highlight') => {
  if (valueParam === undefined || valueParam === null) {
    return '-';
  }

  const value = String(valueParam); // make it a string in any case

  if (!value || !filter) {
    return emptyDash(value);
  }
  const splitFilters = filter.split(' ');
  const splitValues = value.split(' ');

  const results = splitValues
  .map((splitValue, index) => {
    const result = splitFilters
    .map((splitFilter, splitFilterIndex) => [
      splitValue.toLowerCase().indexOf(splitFilter.toLowerCase()),
      splitFilterIndex,
    ])
    .reduce((curr, acc) => {
      const [ indexOf ] = curr;
      // returns first match
      if (indexOf !== -1) {
        return curr;
      }

      return acc;
    }, [ -1, -1 ]);

    // console.log(result);
    const [ indexOf, splitFilterIndex ] = result;

    if (indexOf !== -1) {
      const splitFilter = splitFilters[splitFilterIndex];
      const before = splitValue.substring(0, indexOf);
      const matched = splitValue.substring(indexOf, indexOf + splitFilter.length);
      const after = splitValue.substring(indexOf + splitFilter.length);

      return (
        <React.Fragment key={index}>
          { before && <span>{ before }</span> }
          <span className={highlightClassName}>{ matched }</span>
          { after && <span>{ after }</span> }
          &nbsp;
        </React.Fragment>
      );
    }

    return (
      <React.Fragment key={index}>
        { splitValue }
        &nbsp;
      </React.Fragment>
    );
  });

  return results;
};

export const easyDateMY = (date) => {
  if (!date) {
    return emptyDash(date);
  }

  const dateObj = new Date(date);
  let month = dateObj.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  return `${month}/${dateObj.getFullYear()}`;
};

// Takes the content of a TextArea and converts it to markdown. Used for mail invitation text.
export const convertEditorContentToMailMD = (content) => {
  if (!content) {
    return undefined;
  }

  const markdownText = stateToMarkdown(convertFromRaw(JSON.parse(content)));
  const cleanedText = markdownText
  .replace(/([\u200B]+|[\u200C]+|\u200D+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '')
  .trim();

  if (!cleanedText) {
    return undefined;
  }
  return `[blu-markdown-mail]\n\n${cleanedText}`;
};

// export const translate = translator;

export const markdownIdentifier = '[blu-markdown]';
export const markdownAllowedTags = [
  'h1', 'h2', 'h3', 'h4', 'h5',
  'p', 'a', 'img', 'del', 'strong', 'em', 'code', 'blockquote',
  'table', 'thead', 'tbody', 'th', 'tr', 'td',
  'ul', 'ol', 'li',
  'hr', 'br', 'span',
];
export const markdownAllowedAttributes = {
  h1: 'id',
  h2: 'id',
  p: 'data-blu',
};
const markdownTOCIdentifier = '[blu-table-of-contents]';
const markdownTOCRegex = /\[blu-table-of-contents\]\(([\w\s\d.äÄöÖüÜß!?\-_]*)\)*/;
const htmlH2Regex = /<h2 id="([\w\s\d.äÄöÖüÜß!?\-_]*)">([\w\s\d.äÄöÖüÜß!?\-_]*)<\/h2>/;

const convertMarkdownToHtml = (markdownString) => {
  // parse markdown to html
  let htmlString = marked(markdownString);
  // add attribute to target images in paragraphs
  htmlString = htmlString.replace(/<p><img/g, '<p data-blu="image-container"><img');
  // sanitize the htmlString
  return sanitize(htmlString, {
    allowedTags: markdownAllowedTags,
    allowedAttributes: markdownAllowedAttributes,
  });
};

export const markdown = (string) => {
  if (typeof string !== 'string') {
    return string;
  }

  // do nothing if we aren't handling a markdown string
  if (!string.startsWith(markdownIdentifier)) {
    return string;
  }

  // extract markdown string
  let markdownString = string.replace(markdownIdentifier, '');

  // add Table Of Contents markdown if needed
  if (markdownString.includes(markdownTOCIdentifier)) {
    let tocMarkdown = '';
    // extract tocTitle
    const [ , tocTitle ] = markdownString.match(markdownTOCRegex);
    tocMarkdown += `## ${tocTitle}\n`;
    // parse HTML and add anchors
    const h2Matches = convertMarkdownToHtml(markdownString).match(new RegExp(htmlH2Regex, 'g'));
    h2Matches.forEach((h2Match) => {
      const [ , anchor, title ] = h2Match.match(htmlH2Regex);
      tocMarkdown += `[${title}](#${anchor})  \n`;
    });
    markdownString = markdownString.replace(markdownTOCRegex, tocMarkdown);
  }

  // return jsx result
  return (
    <div
      className='blu-markdown'
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: convertMarkdownToHtml(markdownString) }}
    />
  );
};
