import React, { lazy } from 'react';

const RolesMain = lazy(() => import('./pages/RolesMain' /* webpackChunkName: "roles" */));


export const routesConfigRoles = {
  show: {
    forUserGroup: [ 'leader', 'assessmentManager', 'admin' ],
    forFeature: 'roles',
    forCapabilities: { leader: 'rolesView' },
  },
  routes: [
    {
      path: '/roles',
      PageComponent: () => <RolesMain />,
      LazyPageComponent: 'RoleOverview',
      inMainNav: {
        labelKey: 'main_navigation_item_3',
      },
      subRoutes: [
        {
          path: '/roles',
          PageComponent: () => <RolesMain />,
          LazyPageComponent: 'RoleOverview',
          inSubNav: {
            labelKey: 'subnavigation_bc_roles',
          },
        },
        {
          path: '/role-templates',
          PageComponent: () => <RolesMain />,
          LazyPageComponent: 'RoleTemplatesOverview',
          inSubNav: {
            labelKey: 'templates',
          },
        },
        {
          path: '/roles/:roleId/reference-profile',
          PageComponent: () => <RolesMain />,
          LazyPageComponent: 'RoleReferenceProfile',
          tools: {
            browserTitleKey: 'main_navigation_item_3',
          },
          subRoutes: [
            {
              path: '/roles/:roleId/reference-profile',
              PageComponent: () => <RolesMain />,
              LazyPageComponent: 'RoleReferenceProfile',
              tools: {
                browserTitleKey: 'main_navigation_item_3',
              },
              inSubNav: {
                labelKey: 'role_subnav_ref_profile',
              },
            },
            {
              path: '/roles/:roleId/staffing',
              PageComponent: () => <RolesMain />,
              LazyPageComponent: 'Staffing',
              inSubNav: {
                labelKey: 'role_subnav_matching',
                // also highlight /roles/:roleId/staffing/:employeeId
                alsoHighlightFor: [ 'staffing/' ],
              },
              show: {
                forCapabilities: { leader: 'rolesMatch' },
              },
              tools: {
                browserTitleKey: 'main_navigation_item_3',
              },
            },
            {
              path: '/roles/:roleId/staffing/:employeeId',
              PageComponent: () => <RolesMain />,
              LazyPageComponent: 'RoleMatchedUser',
              tools: {
                browserTitleKey: 'main_navigation_item_3',
              },
            },
            {
              path: '/roles/:roleId/details',
              PageComponent: () => <RolesMain />,
              LazyPageComponent: 'RoleDetails',
              inSubNav: {
                label: 'Details',
              },
              tools: {
                browserTitleKey: 'main_navigation_item_3',
              },
            },
          ],
        },
        {
          path: '/role-templates/:templateId',
          PageComponent: () => <RolesMain />,
          LazyPageComponent: 'RoleTemplate',
          subRoutes: [],
        },
      ],
    },
    {
      path: '/roles/new/manual',
      pageType: 'modal',
      PageComponent: () => <RolesMain />,
      LazyPageComponent: 'NewManualRole',
      show: {
        forCapabilities: { leader: 'rolesCreate' },
      },
      tools: {
        browserTitleKey: 'main_navigation_item_3',
        heartbeat: true,
      },
    },
    {
      path: '/roles/new/auto',
      pageType: 'modal',
      PageComponent: () => <RolesMain />,
      LazyPageComponent: 'NewAutoRole',
      show: {
        forCapabilities: { leader: 'rolesCreate' },
      },
      tools: {
        browserTitleKey: 'main_navigation_item_3',
      },
    },
    {
      path: '/roles/new/from-template',
      pageType: 'modal',
      PageComponent: () => <RolesMain />,
      LazyPageComponent: 'NewRoleFromTemplate',
      show: {
        forCapabilities: { leader: 'rolesCreate' },
      },
      tools: {
        browserTitleKey: 'main_navigation_item_3',
      },
    },
    {
      path: '/roles/new',
      pageType: 'modal',
      PageComponent: () => <RolesMain />,
      LazyPageComponent: 'NewRole',
      show: {
        forCapabilities: { leader: 'rolesCreate' },
      },
      tools: {
        browserTitleKey: 'main_navigation_item_3',
      },
    },
    {
      path: '/roles/:roleId/copy',
      pageType: 'modal',
      PageComponent: () => <RolesMain />,
      LazyPageComponent: 'DuplicateManualRole',
      show: {
        forCapabilities: { leader: 'rolesCreate' },
      },
      tools: {
        browserTitleKey: 'main_navigation_item_3',
        heartbeat: true,
      },
    },
    {
      path: '/roles/:roleId/edit',
      pageType: 'modal',
      PageComponent: () => <RolesMain />,
      LazyPageComponent: 'EditManualRole',
      show: {
        forCapabilities: { leader: 'rolesCreate' },
      },
      tools: {
        browserTitleKey: 'main_navigation_item_3',
        heartbeat: true,
      },
    },
  ],
};
